import AOS from 'aos';
import Glide from '@glidejs/glide'
import { siblings } from "@glidejs/glide/src/utils/dom";

const CustomActiveClass = function (Glide, Components, Events) {
  var Component = {
    mount() {
      this.changeActiveSlide();
    },

    changeActiveSlide() {
      let slide = Components.Html.slides[Glide.index];
      slide.classList.remove('is-next', 'is-prev');
      slide.classList.add('is-active');

      siblings(slide).forEach((sibling) => {
        sibling.classList.remove('is-active', 'is-next', 'is-prev');
      });

      if(slide.nextElementSibling) {
        slide.nextElementSibling.classList.add('is-next');
      }

      if(slide.previousElementSibling) {
        slide.previousElementSibling.classList.add('is-prev');
      }
    },
  };

  Events.on('run', () => {
    Component.changeActiveSlide();
  });

  return Component;
};

export const sliderLoad = () => {
  if (document.querySelectorAll(".glide").length > 0) {
    new Glide('.glide', {
      type: 'carousel',
      perView: 3,
      focusAt: 'center',
      gap: 64,
      breakpoints: {
        1279: {
          gap: 48
        },
        1024: {
          gap: 32
        },
        768: {
          gap: 24
        },
        599: {
          gap: 0,
          perView: 1
        }
      }
    }).on('mount.after', function () {
      AOS.refresh();
    }).mount({
      'CustomActiveClass': CustomActiveClass
    });
  }
}
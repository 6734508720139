import { createApp, defineAsyncComponent } from 'vue'

// Card Row
const cardRows = document.querySelectorAll(".card-row");
Array.prototype.forEach.call(cardRows, function(el) {
  createApp({
    components: {
      ModalButton: defineAsyncComponent(() => import("../components/ModalButton.vue"))
    }
  }).mount(el)
});
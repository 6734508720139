import Alpine from 'alpinejs';
window.Alpine = Alpine;
Alpine.start();

document.documentElement.classList.remove('no-js');

// Modules

import { cookiePolicy } from './modules/cookiePolicy';
import { aosLoad } from "./modules/aos";
import { sliderLoad } from "./modules/slider";
import { responsiveVideoLoad } from "./modules/responsiveVideo";

// Exports

cookiePolicy(() => {
  if (window.gtmInit) {
    window.gtmInit();
  }
});

aosLoad();
sliderLoad();
responsiveVideoLoad();

// Global Exports
